import { createContext, useContext } from "react";
import createPersistedReducer from "use-persisted-reducer";

const usePersistedReducer = createPersistedReducer('stateUser');

export const OperatorContext = createContext();

export function useOperatorContext(){
    return useContext(OperatorContext);
}

function reducer(state,action){
    switch(action.type){
        case 'updateImageProfile' :
            return { ... state,imageProfile:action.payload};
        case 'changeUserNameAdmin' :
            return { ... state,usernameAdmin:action.payload};
        case 'changeUserNameOperator' :
            return { ... state,usernameOperator:action.payload};
        case 'changeNameOperator' :
            return { ... state,nameOperator:action.payload};
        case 'changeStatusOperator' :
            return { ... state,statusOperator:action.payload};
        case 'changeUserNameCaller' :
            return { ... state,usernameCaller:action.payload};
        case 'changeNameCaller' :
            return { ... state,nameCaller:action.payload};
        case 'changeStatusCaller' :
            return { ... state,statusCaller:action.payload};
        case 'toggleShowKodeDisplay' :
            return {...state,showKodeDisplay:!state.showKodeDisplay};
        case 'updateLogo' :
            return {...state,logo:action.payload};
        case 'initHard' :
            return {...state,initHard:!state.initHard};
        case 'enableRefresh' :
            return {...state, enableRefresh: action.payload};
        case 'finishSpin' :
            return {...state,finishSpin:!state.finishSpin};
        case 'updateLoket' :
            return {...state,loketId:action.payload};
        case 'updateLayanan' :
            return {...state,layananId:action.payload};
        case 'updateSessionId' :
            return {...state,sessionId:action.payload};
        default:
            throw new Error('unexpected action')
    }
}

const initialState = {
    usernameAdmin : "",
    usernameOperator : "",
    nameOperator:"",
    statusOperator:"",
    usernameCaller : "",
    nameCaller : "",
    statusCaller : false,
    showKodeDisplay:false,
    imageProfile : "",
    logo:"",
    initHard:false,
    enableRefresh:true,
    finishSpin:false,
    loketId:"",
    layananId:"",
    sessionId:""
}

export function OperatorProvider({children}){
    const [state,dispatch]=usePersistedReducer(reducer,initialState);

    const operatorContextValue = [state,dispatch];

    return (
        <OperatorContext.Provider value={operatorContextValue}>
            {children}
        </OperatorContext.Provider>
    )
}